/*!

=========================================================
* Light Bootstrap Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import {
	Grid,
	Row,
	Col,
	FormGroup,
	ControlLabel,
	FormControl,
	Form
} from "react-bootstrap";
import moment from 'moment';
import { Card } from "components/Card/Card.jsx";
import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import { UserCard } from "components/UserCard/UserCard.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import {withFirebase} from '../components/Firebase';

import avatar from "assets/img/faces/face-3.jpg";
import { initializeWeeklySummary } from "components/Firebase/helper";

const initalState = {
	womenUpload: true,
	cholesterol: '',
	physicalActivity: '',
	bmi: '',
	weight: '',
	heartRate: '',
	height: '',
	sbloodPressure: '',
	dbloodPressure: 0,
	smoking: '',
	notes: '',
	createdAt: moment().format(),
	gait: '',
	timeUpGo: '',
	heartSmartScale: '',
	heartSmartRisk: '',
	diabetes: '',
	waistCircumference: '',
};

class WomensForm extends Component {
	constructor(props){
		super(props);
		this.state = initalState;
	}

	componentDidMount(){
		//this.DeleteDocumentsInWeeklyIntake();
	    //this.InitializeWeeklyIntake();
		this.GetAllWeeklyIntake();
	}

	/**
	 * Only use this to delete the weeklyIntake collection
	 */
	DeleteDocumentsInWeeklyIntake(){
		this.props.firebase.users().get().then(snapshot => {
			snapshot.forEach((doc) => {
				console.log(doc.id, doc.data())

				this.props.firebase.users().doc(doc.id).delete().then(function() {
					console.log("Document successfully deleted!");
				}).catch(function(error) {
					console.error("Error removing document: ", error);
				});
            });
        });
	}


	/**
	 * initalize weeklyIntake collection with a -summary- document.
	 * The summary document would hold all Biomechanics form data, and as well quick summary for easy access.
	 * P.S: Only use this if you deleted the weeklyIntake collection.
	 */
	InitializeWeeklyIntake = () => {
		this.props.firebase.weeklyIntakeSummary().set(initializeWeeklySummary()).then(res => {
			console.log('res from initalize', res);
		}).catch(err => {
            console.log("Error", err);
        });
	}

	/**
	 * Get data from weeklyIntake collection
	 */
	GetAllWeeklyIntake = () => {
		this.props.firebase.weeklyIntake().get().then(snapshot => {
			snapshot.forEach((doc) => {
				console.log(doc.id, doc.data());
            });
		});
	}

		//
		// Less than 3 points = low risk
		// Between 3-5 points = moderate risk
		// 6+ points = high risk

	generateHeartSmartRisk = (value) => {
		if(value < 3) { return 'lowRisk' }
		else if(value >=3 && value <=5) { return 'midRisk' }
		else { return 'highRisk' }
	}

	generateHeartSmartScale = () => {
		const { bloodPressure, cholesterol, smoking, diabetes, bmi, waistCircumference, physicalActivity } = this.state;

		// this.props.firebase.userBioCollection().get().then(snapshot => {
		// 	snapshot.forEach((doc) =>{
		// 		if doc.data().womenUpload {
		// 			// set smokeing, map blood pressure, diabetes, bmi
		// 			// set values to biomechancis upload
		//
		// 		}
		// 	})
		// 	// return the points sum
		// })

		var points = [
			(bloodPressure && bloodPressure >= 190) ? 2 : 0,
			(cholesterol && cholesterol >= 200) ? 2 : 0,
			(smoking == 1 || smoking == "1") ? 2 : 0,
			(bmi && bmi >= 18.5) ? 1 : 0,
			(waistCircumference && waistCircumference >= 36) ? 1 : 0,
			(physicalActivity && physicalActivity >= 60) ? -1 : ((physicalActivity && physicalActivity <= 30) ? 1 : 0),
			(diabetes == 1 || diabetes == "1") ? 1 : 0,
		];

		//summing up the variables
		var sum = points.reduce((a, b) => a + b);
		return sum;
	}

	handleSubmit = async (e) => {
		e.preventDefault();
		console.log('Firebase instanceee', this.state);

		//generateHeartSmartScale
		var heartSmartScale = await this.generateHeartSmartScale();
		var heartSmartRisk = await this.generateHeartSmartRisk(heartSmartScale);
		console.log("Heart Smart Scale", heartSmartScale);
		console.log("Heart Smart Risk", heartSmartRisk);

		var formData = this.state;
		formData['heartSmartScale'] = heartSmartScale;
		formData['heartSmartRisk'] = heartSmartRisk;
		//check if user exist
		let getUser = await this.props.firebase.getUserDoc(localStorage.email).get().then(userDoc => {
			formData['firstName'] = userDoc.data().firstName;
			formData['lastName'] = userDoc.data().lastName;
			formData['email'] = userDoc.data().email;
		});

		this.setState(initalState, async () => {
			console.log(formData, "FormData being posted")
			//submit form data to firestore and update summary
			/*const result = await CreateBioMechanicsData(formData);
			console.log("Result", result);*/
			this.props.firebase.createBioMechanicsData(formData)
			.then(result => {
				console.log("Result", result)
			}).catch(err => {
				console.log("Error", err)
			})
		});
	};

	handleChange = e => {
		console.log('ee target', e.target.name, 'val', e.target.value);
		this.setState({[e.target.name]: e.target.value})
	}

	render() {
		const {name, cholesterol, physicalActivity, bmi, weight, heartRate, height,
			 bloodPressure, smoking, notes, gait, createdAt, timeUpGo, diabetes, waistCircumference} = this.state;
		return (
			<div className="content">
				<Grid fluid>
					<Row>
						<Col md={12}>
							<Card
                                title= "If you track it you can improve it"
								content={
									<form onSubmit={this.handleSubmit} >

                                        <FormInputs
										ncols={["col-md-4"]}
										properties={[
											{
												label: "Date Recorded",
												type: "date",
												bsClass: "form-control",
												placeholder: "Date Added",
												value: createdAt,
												name: 'createdAt',
                                                onChange: this.handleChange
                                            }
                                        ]}
                                        />


										<FormInputs
										ncols={["col-md-5", "col-md-3", "col-md-4"]}
										properties={[
											{

					              label: "Physical Activity (min)",
												type: "number",
												bsClass: "form-control",
												placeholder: "Physical Activity",
												name: 'physicalActivity',
												value: physicalActivity,
												onChange: this.handleChange
                                            },
                                            {
												label: "Weight (lbs)",
												type: "number",
												bsClass: "form-control",
												placeholder: "Weight",
												value: weight,
												name: 'weight',
												onChange: this.handleChange
                                            },
                                            {
												label: "Waist Circumference",
												type: "number",
												bsClass: "form-control",
												placeholder: "Waist Circumference",
												value: waistCircumference,
												name: 'waistCircumference',
												onChange: this.handleChange
											}
										]}
										/>

										<Row>
											<Col md={12}>
												<FormGroup controlId="formControlsTextarea">
												<ControlLabel>Extra Notes</ControlLabel>
												<FormControl
													rows="5"
													componentClass="textarea"
													bsClass="form-control"
													placeholder="Here can be your description"
													value={notes}
													name='notes'
													onChange={this.handleChange}
												/>
												</FormGroup>
											</Col>
										</Row>
										<Button bsStyle="info" pullRight fill type="submit">
											Update Profile
										</Button>
										<div className="clearfix" />
									</form>
								}
							/>
						</Col>
					</Row>
				</Grid>
			</div>
		);
	}
}

export default withFirebase(WomensForm);
