import firebase from './firebase';
import moment from 'moment';
import {ZeroCountCheck, ZeroTotalCheck, ZeroAverageCheck} from 'helper/functions.jsx'

export const initializeWeeklySummary = () => (
    {
        total: {
            bmi: Number(0),
            heartRate: Number(0),
            weight: Number(0),
            heartSmartScale: Number(0),
            physicalActivity: Number(0),
            sBloodPressure: Number(0),
            dBloodPressure: Number(0),
            waistCircumference: Number(0),

        },
        average: {
            bmi: Number(0),
            heartRate: Number(0),
            weight: Number(0),
            heartSmartScale: Number(0),
            physicalActivity: Number(0),
            sBloodPressure: Number(0),
            dBloodPressure: Number(0),
            waistCircumference: Number(0),
        },
        entryCount: {
            bmi: Number(0),
            heartRate: Number(0),
            weight: Number(0),
            heartSmartScale: Number(0),
            physicalActivity: Number(0),
            sBloodPressure: Number(0),
            dBloodPressure: Number(0),
            waistCircumference: Number(0),
        },
        heartSmartRisk: {
            total: Number(0),
            lowRisk: Number(0),
            midRisk: Number(0),
            highRisk: Number(0),
            physicalActivity: Number(0),
            sBloodPressure: Number(0),
            dBloodPressure: Number(0),
            waistCircumference: Number(0),

        },
        yearlyAverage: {}
    }
);

export const initializeWeeklyAverage = () => (
    {
        count: {
            bmi: 0,
            heartRate: 0,
            weight: 0,
            physicalActivity: 0,
            sBloodPressure: 0,
            dBloodPressure: 0,
            waistCircumference: 0,
        },
        average: {
            bmi: 0,
            heartRate: 0,
            weight: 0,
            physicalActivity: 0,
            sBloodPressure: 0,
            dBloodPressure: 0,
            waistCircumference: 0,
        },
        total: {
            bmi: 0,
            heartRate: 0,
            weight: 0,
            physicalActivity: 0,
            sBloodPressure: 0,
            dBloodPressure: 0,
            waistCircumference: 0,
        },
    }
);

export const generateWeeklySummaryData = (data, summaryData) => {

    //get BMI and CreatedAt from data
    let BMI = Number(data.bmi);
    let HeartRate = Number(data.heartRate);
    let Weight = Number(data.weight);
    let PhysicalActivity  = Number(data.physicalActivity);
    let SbloodPressure = Number(data.sBloodPressure || data.sbloodPressure);
    let DbloodPressure = Number(data.dBloodPressure || data.dbloodPressure);
    let WaistCircumference = Number(data.waistCircumference);
    data.createdAt = moment(data.createdAt || new Date()).format('MM-DD-YYYY');
    let dateCreated = data.createdAt;
    let heartSmartRisk = data.heartSmartRisk;//lowRisk


    //get count of heartSmartRisk
    var heartSmartRiskData = summaryData.heartSmartRisk;
    var totalHeartSmartRiskData = heartSmartRiskData.total;
    var newTotalHeartSmartRisk = totalHeartSmartRiskData + 1;
    var newHeartSmartRisk = heartSmartRiskData[heartSmartRisk] + 1;

    //get count entries
    var entryCounts = summaryData.entryCount;
    // console.log("entry counts of all the values: ", entryCounts)
    var bmiEntryCount = entryCounts.bmi;
    // console.log("weighEntryCount", weightEntryCount);
    var heartRateEntryCount = entryCounts.heartRate;
    var weightEntryCount = entryCounts.weight;
    var physicalActivityEntryCount = entryCounts.physicalActivity;
    var sBloodPressureCount = entryCounts.sBloodPressure;
    var dBloodPressureCount = entryCounts.dBloodPressure;
    var waistCircumferenceCount = entryCounts.waistCircumference;


    var newBmiEntryCount = ZeroCountCheck(BMI, bmiEntryCount)
    var newHeartRateEntryCount = ZeroCountCheck(HeartRate, heartRateEntryCount);
    var newWeightEntryCount = ZeroCountCheck(Weight, weightEntryCount);
    var newPhysicalActivityEntryCount = ZeroCountCheck(PhysicalActivity, physicalActivityEntryCount);
    var newSBloodPressureCount = ZeroCountCheck(SbloodPressure, sBloodPressureCount);
    var newDBloodPressureCount = ZeroCountCheck(DbloodPressure, dBloodPressureCount);
    var newWaistCircumferenceCount = ZeroCountCheck(WaistCircumference, waistCircumferenceCount);

    //compute total BMI
    var total = summaryData.total;
    var newTotalBMI =  ZeroTotalCheck(BMI, total.bmi);
    var newTotalHeartRate = ZeroTotalCheck(HeartRate, total.heartRate);
    var newTotalWeight = ZeroTotalCheck(Weight, total.weight);
    var newTotalPhysicalActivity = ZeroTotalCheck(PhysicalActivity, total.physicalActivity);
    var newTotalSBloodPressure = ZeroTotalCheck(SbloodPressure, total.sBloodPressure);
    var newTotalDBloodPressure = ZeroTotalCheck(DbloodPressure, total.dBloodPressure);
    var newTotalWaistCircumference = ZeroTotalCheck(WaistCircumference, total.waistCircumference);

    // Compute new average BMI
    var average = summaryData.average;
    var newAverageBMI = ZeroAverageCheck(newTotalBMI, newBmiEntryCount, BMI, average.bmi);
    var newAverageHeartRate = ZeroAverageCheck(newTotalHeartRate, newHeartRateEntryCount, HeartRate, average.heartRate);
    var newAverageWeight = ZeroAverageCheck(newTotalWeight, newWeightEntryCount, Weight, average.weight);
    var newAveragePhysicalActivity = ZeroAverageCheck(newTotalPhysicalActivity, newPhysicalActivityEntryCount, PhysicalActivity, average.physicalActivity);
    var newAverageSBloodPressure = ZeroAverageCheck(newTotalSBloodPressure, newSBloodPressureCount, SbloodPressure, average.sBloodPressure);
    var newAverageDBloodPressure = ZeroAverageCheck(newTotalDBloodPressure, newDBloodPressureCount, DbloodPressure, average.dBloodPressure);
    var newAverageWaistCircumference = ZeroAverageCheck(newTotalWaistCircumference, newWaistCircumferenceCount, WaistCircumference, average.waistCircumference);

    //set yearly average
    var yearlyAverage = summaryData.yearlyAverage;
    var week = moment(dateCreated).week();
    var year = moment(dateCreated).year();
    var weekAverageData = yearlyAverage && yearlyAverage[`${year}`] && yearlyAverage[`${year}`][`${week}`] || initializeWeeklyAverage();

    //update weekly count, weekly total, and weekly average for this week
    var weeklyTotal = weekAverageData.total;
    var weeklyCount = weekAverageData.count;
    // console.log("Weekly Average Count Data", weeklyCount);
    var weeklyAverage = weekAverageData.average;

    var newWeeklyBMICount = ZeroCountCheck(BMI, weeklyCount.bmi);
    var newWeeklyBMITotal = ZeroTotalCheck(BMI, weeklyTotal.bmi);
    var newWeeklyBMIAvg = ZeroAverageCheck(newWeeklyBMITotal, newWeeklyBMICount, BMI, weeklyAverage.bmi);

    var newWeeklyHeartRateCount = ZeroCountCheck(HeartRate, weeklyCount.heartRate);
    var newWeeklyHeartRateTotal = ZeroTotalCheck(HeartRate, weeklyTotal.heartRate);
    var newWeeklyHeartRateAvg = ZeroAverageCheck(newWeeklyHeartRateTotal, newWeeklyHeartRateCount, HeartRate, weeklyAverage.heartRate);

    var newWeeklyWeightCount = ZeroCountCheck(Weight, weeklyCount.weight);
    // console.log("weekly count for Weight Count", newWeeklyWeightCount)
    var newWeeklyWeightTotal = ZeroTotalCheck(Weight, weeklyTotal.weight);
    var newWeeklyWeightAvg = ZeroAverageCheck(newWeeklyWeightTotal, newWeeklyWeightCount, Weight, weeklyAverage.weight);

    var newWeeklyPhysicalActivityCount = ZeroCountCheck(PhysicalActivity, weeklyCount.physicalActivity);
    var newWeeklyPhysicalActivityTotal = ZeroTotalCheck(PhysicalActivity, weeklyTotal.physicalActivity);
    var newWeeklyPhysicalActivityAvg = ZeroAverageCheck(newWeeklyPhysicalActivityTotal, newWeeklyPhysicalActivityCount, PhysicalActivity, weeklyAverage.physicalActivity);

    var newWeeklySBloodPressureCount = ZeroCountCheck(SbloodPressure, weeklyCount.sBloodPressure);
    // console.log("weekly count for Sblood Pressure", newWeeklySBloodPressureCount)
    var newWeeklySBloodPressureTotal = ZeroTotalCheck(SbloodPressure, weeklyTotal.sBloodPressure);
    var newWeeklySBloodPressureAvg = ZeroAverageCheck(newWeeklySBloodPressureTotal, newWeeklySBloodPressureCount, SbloodPressure, weeklyAverage.sBloodPressure);

    var newWeeklyDBloodPressureCount = ZeroCountCheck(DbloodPressure, weeklyCount.dBloodPressure);
    var newWeeklyDBloodPressureTotal = ZeroTotalCheck(DbloodPressure, weeklyTotal.dBloodPressure);
    var newWeeklyDBloodPressureAvg = ZeroAverageCheck(newWeeklyDBloodPressureTotal, newWeeklyDBloodPressureCount, DbloodPressure, weeklyAverage.dBloodPressure);

    var newWeeklyWaistCircumferenceCount = ZeroCountCheck(WaistCircumference, weeklyCount.waistCircumference);
    var newWeeklyWaistCircumferenceTotal = ZeroTotalCheck(WaistCircumference, weeklyTotal.waistCircumference);
    var newWeeklyWaistCircumferenceAvg = ZeroAverageCheck(newWeeklyWaistCircumferenceTotal, newWeeklyWaistCircumferenceCount, WaistCircumference, weeklyAverage.waistCircumference);

    var newYearlyAverage = {
        ...yearlyAverage,
        [`${year}`]: {
            ...yearlyAverage[`${year}`],
            [`${week}`]: {
                count: {
                    bmi: newWeeklyBMICount,
                    heartRate: newWeeklyHeartRateCount,
                    weight: newWeeklyWeightCount,
                    physicalActivity: newWeeklyPhysicalActivityCount,
                    sBloodPressure:  newWeeklySBloodPressureCount,
                    dBloodPressure: newWeeklyDBloodPressureCount,
                    waistCircumference: newWeeklyWaistCircumferenceCount,
                },
                average: {
                    bmi: newWeeklyBMIAvg,
                    heartRate: newWeeklyHeartRateAvg,
                    weight: newWeeklyWeightAvg,
                    physicalActivity: newWeeklyPhysicalActivityAvg,
                    sBloodPressure: newWeeklySBloodPressureAvg,
                    dBloodPressure: newWeeklyDBloodPressureAvg,
                    waistCircumference: newWeeklyWaistCircumferenceAvg,
                },
                total: {
                    bmi: newWeeklyBMITotal,
                    heartRate: newWeeklyHeartRateTotal,
                    weight: newWeeklyWeightTotal,
                    physicalActivity: newWeeklyPhysicalActivityTotal,
                    sBloodPressure: newWeeklySBloodPressureTotal,
                    dBloodPressure: newWeeklyDBloodPressureTotal,
                    waistCircumference: newWeeklyWaistCircumferenceTotal,
                },
            }
        }
    }

    // Commit to Firestore, update the weeklyIntakeSummary
    let weeklySummaryData = {
        total: {
            bmi: newTotalBMI,
            heartRate: newTotalHeartRate,
            weight: newTotalWeight,
            physicalActivity: newTotalPhysicalActivity,
            sBloodPressure: newTotalSBloodPressure,
            dBloodPressure: newTotalDBloodPressure,
            waistCircumference: newTotalWaistCircumference,
        },
        average: {
            bmi: newAverageBMI,
            heartRate: newAverageHeartRate,
            weight: newAverageWeight,
            physicalActivity: newAveragePhysicalActivity,
            sBloodPressure: newAverageSBloodPressure,
            dBloodPressure: newAverageDBloodPressure,
            waistCircumference: newAverageWaistCircumference,
        },
        entryCount: {
            bmi: newBmiEntryCount,
            heartRate: newHeartRateEntryCount,
            weight: newWeightEntryCount,
            physicalActivity: newPhysicalActivityEntryCount,
            sBloodPressure: newSBloodPressureCount,
            dBloodPressure: newDBloodPressureCount,
            waistCircumference: newWaistCircumferenceCount,
        },
        heartSmartRisk: {
            ...heartSmartRiskData,
            total: newTotalHeartSmartRisk
        },
        yearlyAverage: newYearlyAverage
    };

    if(heartSmartRisk && newHeartSmartRisk){
        weeklySummaryData.heartSmartRisk[`${heartSmartRisk}`] = newHeartSmartRisk;
    }

    return weeklySummaryData;
}
